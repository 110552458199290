import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Spin, Layout, Footer } from 'style-guide';
import { userTypes } from 'bmiq-common';
import { useRedux } from 'hooks/reduxHooks';
import { useLoggedInUser } from 'hooks/userHooks';
import { useQueryParams, useRouterLocation, useRouterHistory } from 'hooks/routerHooks';
import useGetRequest from 'hooks/useGetRequest';

import { requestSystemMessages } from 'actions/system-messages';
import { zendeskApiGet } from 'services/zendesk';
import { initAuthLoading } from 'selectors/users';
import asyncComponent from '../asyncComponent';
import ErrorBoundary from '../ErrorBoundary';
import MainSpinWrapper from './MainSpinWrapper';

const AuthenticatedRoutes = asyncComponent(() => import('./AuthenticatedRoutes'));
const UnAuthenticatedRoutes = asyncComponent(() => import('./UnAuthenticatedRoutes'));
const MainNavigation = asyncComponent(() => import('../MainNavigation'));
const FooterNav = asyncComponent(() => import('../MainNavigation/FooterNav'));
const QuickAddButton = asyncComponent(() => import('../QuickAddButton'));

const Header = asyncComponent(() => import('../Main/_partials/Header'));

const patientRoutes = [
  'account',
  'schedule-appointment',
  'learning',
  'assessment',
  'devices',
  'recommendations',
  'messaging',
  'logging',
  'goals',
  'follow-up-form',
  'hra-form',
  'self-registration',
];
const providerRoutes = ['patient', 'patients', 'learning', 'insights', 'search', 'orientation', 'account', 'appointments', 'rpm-logs', 'messaging'];
const organizationRoutes = ['company-directory', 'schedule', 'learning', 'insights', 'search', 'rpm-logs'];
const deduRoutes = ['customize-learning'];

const AppRouter = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [dispatch, select] = useRedux();
  const initLoader = select(initAuthLoading);
  const { token, resetToken, email, patientId, asAdmin, return_to: returnTo, zendesk: zendeskQuery } = useQueryParams();
  const { pathname, state } = useRouterLocation();
  const history = useRouterHistory();
  const { type: userType, twoFaWaiting } = useLoggedInUser() || {};
  const waitForRedirect = !!token || !!resetToken || !!email || twoFaWaiting;
  const [zendeskData, setZendeskData] = useState({});
  const getSystemMessages = useCallback(() => dispatch(requestSystemMessages()), [dispatch]);
  useEffect(() => getSystemMessages(), [getSystemMessages]);
  useEffect(() => {
    if (userType && waitForRedirect && !history.location.state?.orientation) {
      history.push(pathname);
    }
  }, [history, pathname, userType, waitForRedirect]);

  useEffect(() => {
    if (userType && !waitForRedirect && state?.from) {
      const checkRouteAvailability = () => {
        let baseUrl = state.from.split('/')[1];
        if (baseUrl.includes('?')) {
          baseUrl = baseUrl.split('?')?.[0];
        }
        switch (userType) {
          case userTypes.USER_TYPE_PATIENT:
            return patientRoutes.includes(baseUrl);
          case userTypes.USER_TYPE_PROVIDER_ADMIN:
            return providerRoutes.includes(baseUrl);
          case userTypes.USER_TYPE_ORGANIZATION_ADMIN:
            return organizationRoutes.includes(baseUrl);
          case userTypes.USER_TYPE_ADMIN:
            return true;
          case userTypes.USER_TYPE_DEDU:
            return deduRoutes.includes(baseUrl);
          default:
            return false;
        }
      };
      checkRouteAvailability() && history.push(state.from, {});
    }
  }, [history, waitForRedirect, state, userType]);

  const showHeader =
    !['/orientation', '/not-found', '/not-permitted', '/learnings/', '/get-app', '/visit', '/messages', '/follow-up-form', 'hra-form'].some(url =>
      pathname.includes(url),
    ) && !twoFaWaiting;

  const showFooter = !['/visit', '/learnings/', '/messages', '/follow-up-form', 'hra-form'].some(url => pathname.includes(url));
  const showNav =
    !['/orientation', '/not-found', '/not-permitted', '/learnings/', '/get-app', '/visit', '/messages', '/follow-up-form', 'hra-form'].some(url =>
      pathname.includes(url),
    ) && !twoFaWaiting;

  const transparentBg =
    ['/learnings/', '/get-app', '/sign-in', '/visit', '/messages', '/follow-up-form', 'hra-form'].some(url => pathname.includes(url)) ||
    userType === undefined
      ? 'light'
      : 'dark';
  const zendeskRequestParams = React.useMemo(() => ({ return_to: zendeskData.redirectUrl, help: true }), [zendeskData.redirectUrl]);
  const isMobile = ['/learnings/', '/follow-up-form', '/hra-form'].some(url => pathname.includes(url));

  useEffect(() => {
    if (zendeskQuery) {
      setZendeskData({
        redirectUrl: returnTo,
        zendesk: true,
      });
    }
  }, [zendeskQuery, returnTo]);

  useEffect(() => {
    if (!initLoader && !userType && pathname === '/follow-up-form') {
      history.replace('/sign-in', { from: '/follow-up-form' });
    }
  }, [userType, initLoader, pathname, history]);

  const zendeskSuccess = useCallback(data => {
    if (data.success) {
      window.location.assign(data.redirectUrl);
    }
  }, []);

  useGetRequest({
    request: zendeskApiGet,
    onSuccess: zendeskSuccess,
    condition: !!userType && !!zendeskData.redirectUrl,
    params: zendeskRequestParams,
    initialData: { success: false },
  });

  const [burgerVisible, setBurgerVisible] = React.useState(false);
  const toggleBurger = () => setBurgerVisible(v => !v);

  return initLoader ? (
    <MainSpinWrapper>
      <Spin />
    </MainSpinWrapper>
  ) : (
    <ErrorBoundary>
      {showNav && userType === userTypes.USER_TYPE_PATIENT && <QuickAddButton />}
      {showHeader && <Header toggleBurger={toggleBurger} setBurgerVisible={setBurgerVisible} burgerVisible={burgerVisible} />}
      {userType && !waitForRedirect ? (
        <Layout hasSider={showNav} collapsed={collapsed} $transparentBg={transparentBg} $hasHeader={showHeader} $isMobile={isMobile}>
          <Fragment>
            {showNav && (
              <MainNavigation
                asAdmin={asAdmin}
                patientId={patientId}
                userType={userType}
                collapsed={collapsed}
                history={history}
                onCollapse={() => setCollapsed(c => !c)}
                burgerVisible={burgerVisible}
                setBurgerVisible={setBurgerVisible}
              />
            )}
            <AuthenticatedRoutes userType={userType} />
            {showNav && <FooterNav />}
          </Fragment>
        </Layout>
      ) : (
        <Layout $transparentBg={transparentBg}>
          <UnAuthenticatedRoutes />
        </Layout>
      )}
      {showFooter && <Footer clientUrl={window.location.origin} />}
    </ErrorBoundary>
  );
};
export default AppRouter;
