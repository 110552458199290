import React from 'react';
import styled from 'styled-components';
import { Tabs as tabs } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';

const { TabPane } = tabs;
const StyledTabs = styled(tabs)`
  .ant-tabs-tab {
    padding: 8px;
  }

  > .ant-tabs-nav .ant-tabs-nav-list,
  > div > .ant-tabs-nav .ant-tabs-nav-list {
    flex: unset;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 700;
    color: ${props => (props.rebrandProviderWebAppPhase3 ? '#184FBC' : '')};
  }
`;

const Tabs = ({ ...props }) => {
  const { rebrandProviderWebAppPhase3 } = useFlags();

  return <StyledTabs {...props} rebrandProviderWebAppPhase3={rebrandProviderWebAppPhase3} />;
};
Tabs.TabPane = TabPane;

/** @component */
export default Tabs;
