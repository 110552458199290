import styled from 'styled-components';
import Button from './index';

const { Group } = Button;
const ButtonGroup = styled(Group)`
  > .ant-btn {
    border-radius: 44px !important;
  }
`;
/** @component */
export default ButtonGroup;
