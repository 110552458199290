import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { Modal as modal } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk'; // Import useFlags

const StyledModal = styled(modal)`
  max-height: 100vh !important;

  .wysiwyg-mention {
    color: black;
    font-weight: bold;
    pointer-events: none;
    cursor: default;
  }

  ${props =>
    props.$bodyHeight &&
    css`
      .ant-modal-body {
        height: ${props.$bodyHeight};
      }
    `}
  ${props =>
    props.type === 'standard' &&
    css`
      .ant-modal-body {
        overflow: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        /* Hide scrollbar for Chrome, Safari and Opera */
        ::-webkit-scrollbar {
          display: none;
        }
      }
      min-width: 640px !important;
    `}
  ${props =>
    props.type === 'medium' &&
    css`
      .ant-modal-body {
        min-height: 70vh;
      }
      min-width: 860px;
    `}
  ${props =>
    props.type === 'small' &&
    css`
      .ant-modal-body {
        min-height: 40vh;
      }
      min-width: 260px;
    `}
  ${props =>
    props.type === 'large' &&
    css`
      .ant-modal-body {
        min-height: 85vh;
      }
      min-width: 1080px;
    `}
  ${props =>
    props.noPadding &&
    css`
      .ant-modal-body {
        padding: 0 !important;
      }
    `}

  .ant-modal-title {
    font-weight: bold;
  }

  .ant-modal-content {
    border-radius: 4px;
    border: 0;
    box-shadow: none;
  }

  .bottom-section {
    position: absolute;
    bottom: 60px;
    padding: 0 24px;
    background: #fff;
  }

  .ant-modal-confirm-title {
    color: #fa7d19 !important;
  }

  .ant-modal-footer {
    position: sticky;
    width: 100%;
    bottom: 0;
    right: 0;
    background: #fff;
    z-index: 2;
    border-top: 1px solid #cfd0d3 !important;

    button {
      border-radius: ${props => (props.rebrandProviderWebAppPhase3 ? '40px' : '4px')};
      height: 40px;
      padding: 6.4px 20px;
    }

    > button + button {
      margin-left: 0;
      margin-top: 8px;
      background: ${props => (props.rebrandProviderWebAppPhase3 ? '#1e61e6' : '#137ac9')};
    }
  }

  .ant-modal-header {
    border-bottom: 1px solid #cfd0d3 !important;
    text-align: center;

    .left-extra {
      transition-duration: 0.5s;
      padding: 4px;
      border-radius: 4px;
      cursor: pointer;
      color: #626469;
      position: absolute;
      top: 5px;
      left: 24px;
      font-size: 14px;
      line-height: 1;

      button {
        color: #626469;
        border-radius: 4px;
        transition-duration: 0.5s;
        line-height: 1;
        padding: 4px;
        border: none;
      }

      button:active {
        background: #e0e0e0;
      }
    }

    /* to be removed */
    .modal-back {
      position: absolute;
      top: 20px;
      left: 24px;
      cursor: pointer;
      font-size: 14px !important;
      color: #b8b8b8 !important;
      line-height: 1 !important;

      svg {
        position: relative;
        top: -1px;
      }
    }
  }

  .modal-section {
    padding: 12px 24px;
  }

  .modal-section-data {
    padding: 24px;
    background: #fff;

    .ant-table-thead {
      .ant-table-cell {
        color: #626469;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }

  .section {
    padding: 24px;
    background: #f8f8f8;
  }

  @media screen and (max-width: 991.98px) {
    margin: 0 auto !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    max-width: 100vw !important;
    min-width: 100vw !important;
    width: 100vw !important;

    .ant-modal-body {
      height: calc(100vh - 55px - 60px) !important;
      border-radius: 0;
    }

    > button {
      width: 100% !important;
    }

    > button + button {
      margin-top: 8px;
    }
  }
`;

const Modal = ({ title, noPadding = false, type = 'standard', $bodyHeight = '', extraLeft = '', bottomSection = '', ...props }) => {
  const { rebrandProviderWebAppPhase3 } = useFlags();
  const primaryColor = rebrandProviderWebAppPhase3 ? '#1e61e6' : '#137ac9';
  Modal.confirm = modalProps =>
    modal.confirm({
      ...modalProps,
      okButtonProps: {
        style: {
          background: primaryColor,
        },
        size: 'large',
        shape: 'round',
      },
      cancelButtonProps: {
        style: {
          color: primaryColor,
          border: `1px solid ${primaryColor}`,
        },
        size: 'large',
        shape: 'round',
      },
    });

  Modal.info = modalProps =>
    modal.info({
      ...modalProps,
      okButtonProps: {
        style: {
          background: primaryColor,
        },
        size: 'large',
        shape: 'round',
      },
    });

  return (
    <StyledModal
      $bodyHeight={$bodyHeight}
      noPadding={noPadding}
      rebrandProviderWebAppPhase3={rebrandProviderWebAppPhase3}
      type={type}
      title={
        title && (
          <Fragment>
            <span className='left-extra'>{extraLeft}</span> {title}
          </Fragment>
        )
      }
      {...props}
    >
      {props.children}
      {<div className='bottom-section'>{bottomSection}</div>}
    </StyledModal>
  );
};

StyledModal.defaultProps = {
  centered: true,
  zIndex: 1000,
  type: 'standard',
};

Modal.confirm = StyledModal.confirm;
Modal.info = StyledModal.info;
Modal.warning = StyledModal.warning;
Modal.error = StyledModal.error;

/** @component */
export default Modal;
