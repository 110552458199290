import React from 'react';
import styled, { css } from 'styled-components';
import { Card as Component } from 'antd';

const Card = styled(
  ({ titleBackground, actionWidth, actionPosition, actionMargin, actionBackground, shadow, level, actionBorder, mode, ...props }) => (
    <Component {...props} />
  ),
)`
  ${props =>
    props &&
    props.mode &&
    props.mode === 'standard' &&
    css`
      background: #ffffff;
      box-shadow: 0px 0px 8px #cfd0d3;
      border-radius: 4px !important;
    `}
  && {
    .ant-card-body .mention {
      background: white;
    }

    &.medical-area-card {
      margin-bottom: 15px;
      box-shadow: 0 0 8px #cfd0d3;
      border-radius: 4px;

      &.text-align {
        text-align: center;
      }

      & > .ant-card-body {
        padding: 0;
      }
    }

    &.message-card {
      @media screen and (max-width: 576px) {
        width: 100%;
        display: block;
      }

      width: 100%;
      height: 514px;
      overflow: hidden;
      border-top: 0;
      border-left: 0;
      border-right: 0;
    }

    ${props =>
      props &&
      props.level &&
      props.level === 1 &&
      css`
        .ant-card-body {
          padding-right: 0 !important;
          padding-left: 0 !important;
        }
      `};
    border-radius: ${props => props.$borderRadius} !important;
    ${props =>
      props &&
      props.shadow &&
      props.shadow === 1 &&
      css`
        .ant-card-body {
          padding: 0px !important;
        }

        &:hover {
          box-shadow: 0px -5px 14px -3px rgba(176, 176, 176, 1);
          border: 1.5px solid lightgray;

          .complete-icon {
            padding-top: 11px;
          }
        }
      `};
    ${props =>
      props.actionWidth &&
      props.actionPosition &&
      props.actionMargin &&
      props.actionBackground &&
      css`
        .ant-card-actions {
          width: ${props.actionWidth};
          position: ${props.actionPosition};
          bottom: ${props.actionMargin};
          background: ${props.actionBackground};
        }
      `};
    ${props =>
      props.titleBackground &&
      css`
        .ant-card-head {
          background: ${props.titleBackground};
        }
      `};
  }
`;
Card.Grid = Component.Grid;
Card.Meta = Component.Meta;

/** @component */
export default Card;
