import styled, { css } from 'styled-components';
import { Typography } from 'antd';

const { Title } = Typography;

export const fontSizes = {
  1: 48,
  2: 36,
  3: 28,
  4: 16,
  5: 14,
};

const StyledTitle = styled(Title)`
  font-family: 'Centra No2';
  color: #1d1d1d;
  line-height: 1 !important;
  margin-bottom: 16px !important;
  font-size: ${props => fontSizes[props.level]}px !important;
  display: block;
  width: 100%;
  ${props =>
    props.type &&
    props.type === 'headline' &&
    css`
      background-color: #f8f8f8;
      padding: 24px;
      margin-bottom: 0;
    `}

  svg {
    margin-right: 12px;
    vertical-align: middle;
  }
`;

StyledTitle.defaultProps = {
  level: 3,
};

/** @component */
export default StyledTitle;
