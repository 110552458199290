import React, { forwardRef } from 'react';
import { Steps as StepsComponent } from 'antd';
import styled from 'styled-components';
import StreamlineIcon from 'style-guide/StreamlineIcon';

const customDots = (dot, { status, index }) =>
  status === 'finish' ? <StreamlineIcon icon='check-circle-1-stroked' size={18} className='custom-dot' /> : dot;

const Steps = styled(forwardRef(({ level, ...props }, ref) => <StepsComponent {...props} ref={ref} progressDot={customDots} />))`
  .custom-dot {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -30%);
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after,
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background-color: #1e61e6;
  }
`;

Steps.Step = StepsComponent.Step;
export default Steps;
