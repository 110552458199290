import { Alert as alert } from 'antd';
import styled, { css } from 'styled-components';

export const colors = {
  error: {
    background: '#FBF4F4',
    border: '#F0D6D2',
  },
  warning: {
    background: '#FFFCF9',
    border: '#FDF1E1',
  },
  info: {
    background: '#F4F7FB',
    border: '#D3E2F0',
  },
  success: {
    background: '#F9FCF6',
    border: '#D8E5D5',
  },
  notification: {
    background: '#FFF',
    border: '',
  },
};

interface StyledAlertProps {
  size?: 'small';
  position?: 'absolute';
}

const Alert = styled(alert)<StyledAlertProps>`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 24px !important;
  border-radius: 4px;
  margin-bottom: 16px;

  .ant-alert-info {
    color: #1e61e6;
  }

  ${props =>
    props.size &&
    props.size === 'small' &&
    css`
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      padding-right: 8px !important;
    `}
  ${props =>
    props.position &&
    props.position === 'absolute' &&
    css`
      position: absolute !important;
      z-index: 999999 !important;
      top: 0;
      left: 0;
    `}
  ${props =>
    props.type &&
    css`
      background-color: ${colors[props.type] && colors[props.type].background} !important;
    `}

  .ant-alert-message {
    font-weight: bold;
    line-height: 20px;
    padding-bottom: 8px;
    ${props =>
      !props.message &&
      css`
        display: none !important;
      `}
  }

  .anchor {
    color: #1e61e6 !important;
    cursor: pointer;
  }
`;
export default Alert;
