import styled from 'styled-components';
import { Carousel as carousel } from 'antd';

const Carousel = styled(carousel)`
  background: #fff;

  > .slick-dots li button {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    border: 2px solid #1e61e6;
    opacity: 100%;
    margin-top: 48px;
  }

  > .slick-dots li.slick-active button {
    border-radius: 100%;
    background: #1e61e6;
    border: 2px solid #1e61e6;
    margin-top: 48px;
    opacity: 100%;
  }

  @media screen and (max-width: 767px) {
    > .slick-dots li button,
    > .slick-dots li.slick-active button {
      margin-top: 32px;
    }
  }
`;

export default Carousel;
