import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import Paragraph from 'style-guide/Paragraph';
import Title from 'style-guide/Title';
import Button from 'style-guide/Button';
import Img from 'style-guide/Img';
import RpmIcon from 'assets/imgs/rpm-icon.png';
import Svg from '../../components/partials/Icons/CustomIcon';

const card = ({ ...props }) => <div {...props} />;
const StyledCard = styled(card)`
  box-shadow: 0 0 8px #cfd0d3;
  border: none !important;
  padding: 24px;
  width: 100%;
  background: white;
  min-height: 155px;
  border-radius: 4px !important;
`;

const GoalCard = ({ icon, onClick, item, buttonText, title = null, description = null }) => (
  <StyledCard onClick={() => onClick(item)}>
    <Row gutter='24'>
      <Col span={6}>{item.type === 'rpm' ? <Img src={RpmIcon} width={50} /> : <Svg icon={icon} type='filled' color='blue' />}</Col>
      <Col span={18}>
        <Title level={4}>{title}</Title>
        <Paragraph ellipsis>{description}</Paragraph>
        {buttonText && (
          <Button type='link' onClick={() => onClick(item)}>
            {buttonText}
          </Button>
        )}
      </Col>
    </Row>
  </StyledCard>
);

/** @component */
export default GoalCard;
