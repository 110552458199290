import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { Card as Component } from 'antd';

const card = ({ noPadding, fixedHeight, hideTitle, isDashed, bodyHeight, ...props }) => <Component {...props} />;
const StyledCard = styled(card)`
  background: #fff;
  overflow: hidden;
  box-shadow: 0 0 8px #cfd0d3;
  border: none !important;
  border-radius: 4px !important;

  .ant-card-body {
    margin-top: 1px;
    ${props =>
      props.fixedHeight &&
      props.$bodyHeight &&
      css`
        height: ${props.$bodyHeight}px;
      `}
    position: relative;

    .wysiwyg-mention {
      color: black;
      font-weight: bold;
      pointer-events: none;
      cursor: default;
    }

    a:hover {
      border-bottom: 1px solid;
    }
  }

  .action-buttons {
    float: right;
    padding-right: 24px;

    .cancel-button {
      border: none;
      box-shadow: none;
    }
  }

  ${props =>
    props.noPadding &&
    css`
      .ant-card-body {
        padding: 0 !important;
      }
    `};
  ${props =>
    props.$hideTitle &&
    css`
      .ant-card-head {
        display: none !important;
      }
    `}
  ${props =>
    props.isDashed &&
    css`
      max-width: 415px;
      height: 152px;
      border: 1px dashed #b8b8b8 !important;
    `}
  .ant-card-head {
    border-bottom: 1px solid #cfd0d3 !important;

    .ant-card-head-title {
      text-align: center;
    }

    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }

  .ant-card-extra .anticon {
    color: #8e8e8e;
    display: inline-block;
    border-radius: 4px;
    padding: 4px;
    transition-duration: 0.5s;
    cursor: pointer;
    font-size: 14px;
  }

  .ant-card-extra .anticon:hover {
    color: #1d1d1f;
    background: #efefef;
  }

  .ant-card-extra .anticon:active {
    background: #e0e0e0;
  }

  .section {
    padding: 24px;
    background: #f8f8f8;
  }

  .no-items {
    text-align: center;
    color: #626469;
    width: 100%;
    height: 457px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }

  .left-extra {
    .active-view {
      color: #1e61e6 !important;
      position: relative;
    }

    .active-view::after {
      content: '';
      display: block;
      border: 1px solid #1e61e6;
      position: absolute;
      bottom: -18px;
      left: 0;
      width: 100%;
    }

    color: #626469;
    position: absolute;
    top: 16px;
    left: 24px;
    font-size: 14px;
    line-height: 1;

    button {
      color: #626469;
      border-radius: 4px;
      transition-duration: 0.5s;
      line-height: 1;
      padding: 4px;
      border: none;
    }

    button:hover {
      color: #1d1d1f;
      background: #efefef;
    }

    button:active {
      background: #e0e0e0;
    }
  }
`;

const Card = ({
  title,
  saveAction = null,
  cancelAction = null,
  noPadding = false,
  hasActions = false,
  fixedHeight = true,
  separatorTitle = '',
  extraLeft = '',
  $bodyHeight,
  ...props
}) => (
  <StyledCard
    title={
      <Fragment>
        <span className='left-extra'>{extraLeft}</span> {title}
      </Fragment>
    }
    actions={
      hasActions
        ? [
            <div className='action-buttons'>
              <span>{cancelAction}</span>
              <span>{saveAction}</span>
            </div>,
          ]
        : null
    }
    {...props}
    noPadding={noPadding ? 'true' : undefined}
    fixedHeight={fixedHeight ? 'true' : undefined}
    $bodyHeight={$bodyHeight}
  >
    {props.children}
  </StyledCard>
);

/** @component */
export default Card;
