import styled from 'styled-components';

const OrientationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;

  .innerWrapper {
    width: 930px;
    background: #fff;
    border-radius: 10px;
    z-index: 9999999;
    position: relative;

    @media (max-width: 768px) {
      width: 90%;
    }

    @media (max-width: 480px) {
      width: 100%;
      border-radius: 0;
    }
  }

  .header {
    display: none;
  }

  .slick-list {
    padding: 0;
    top: 0;
  }

  .slick-dots {
    margin: 0;
    padding: 0;
    text-align: center;
    list-style: none;
    position: absolute;
    width: 100%;
    bottom: -40px;

    @media (max-width: 480px) {
      top: calc(40vh + 25px);
      bottom: auto;
    }
  }

  .slick-dots li {
    border-radius: 50%;
    overflow: hidden;
    width: 10px;
    height: 10px;
    display: inline-block;
    border: 1px solid #fff;
    margin: 0 4px;
    cursor: pointer;

    @media (max-width: 480px) {
      border: 1px solid #d0d0d0;
    }

    button {
      opacity: 0;
      cursor: pointer;
    }

    &.slick-active {
      background: #fff;

      @media (max-width: 480px) {
        background: #1e61e6;
      }
    }
  }

  @media (max-width: 480px) {
    width: 100%;
    border-radius: 0;
    height: 100vh;

    .header {
      display: block;
      border-bottom: 1px solid #cfd0d3;
      padding: 16px 0;
      text-align: center;
      font-weight: bold;
      color: #006a97;
      max-height: 54px;
    }
  }

  h3 {
    color: #006a97 !important;
  }

  .slide-illustration {
    background: #f8f8f8;
    height: 465px;

    @media (max-width: 480px) {
      height: 40vh;
      background: #fff;
    }
  }

  .slide-illustration-block {
    overflow: hidden;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .slide-illustration img {
    width: 100%;
    height: 100%;

    @media (max-width: 480px) {
      max-width: 100%;
      height: 40vh;
    }
  }

  .slide-content {
    font-size: 16px;
    line-height: 24px;
    color: #626469;
    text-align: center;
    padding: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 465px;

    @media (max-width: 480px) {
      height: calc(60vh - 54px);
      padding: 65px 20px 20px;
      display: block;

      .button-wrapper {
        border-top: 1px solid #cfd0d3;
        padding: 16px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fff;
      }
    }
  }

  .slide-content-wrrapper {
    flex: 1;
  }

  .slide-content .ant-typography {
    color: #626469;
  }

  .skip {
    cursor: pointer;
    color: #b8b8b8;
    font-size: 16px;
    position: absolute;
    top: 25px;
    right: 25px;

    @media (max-width: 480px) {
      top: 16px;
      right: 16px;
    }
  }

  .slide-image {
    padding: 24px;

    @media (max-width: 480px) {
      padding: 16px;
    }
  }
`;

/** @component */
export default OrientationWrapper;
