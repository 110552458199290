import styled, { css } from 'styled-components';
import { List as list } from 'antd';

const List = styled(list)`
  /* fix for recommendation output, sorry about it */
  p {
    margin: 0;
  }

  /* fix for rows in list */
  .ant-row {
    flex: 1;
  }

  > button {
    width: 100%;
    border-top: 1px solid #cfd0d3 !important;
    border-width: 1px 0 0 0 !important;
    border-radius: 0 0 4px 4px !important;
    padding: 16px;
    box-shadow: none;
  }

  .ant-list-item {
    padding: 16px;
    ${props =>
      props.$clickable &&
      css`
        cursor: pointer;
        :hover {
          background: #f8f8f8;
        }
      `}

    .ant-typography {
      margin-bottom: 0;
    }

    &:first-child {
      border-top: none !important;
    }

    &:last-child {
      border-bottom: none !important;
    }

    border-top: 1px solid #cfd0d3 !important;
    border-bottom: none !important;
    font-size: 16px;

    .ant-typography-secondary {
      font-size: 14px;
    }

    .ant-list-item-meta-title {
      font-size: 16px;
      font-weight: normal;
      font-weight: bold;
    }

    .extra {
      font-size: 12px;
      color: #626469;

      > button {
        margin-left: 8px;
      }
    }

    .ant-list {
      padding-right: 0;
      padding-left: 50px;

      .ant-list-item {
        padding-right: 0;
        padding-left: 0;
        border: none !important;
      }
    }
  }

  ${props =>
    props.$itemPaddings &&
    css`
      .ant-list-item {
        padding: ${props.$itemPaddings.vertical} ${props.$itemPaddings.horizontal};
      }
    `}
`;
/** @component */
export default List;
